@font-face {
  font-family: "Gilroy";
  src: url("../public/Asset/webFonts/Gilroy-Black.ttf") format("woff2"),
    url("../public/Asset/webFonts/Gilroy-Black.ttf") format("woff");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../public/Asset/webFonts/Gilroy-SemiBold.ttf") format("woff2"),
    url("../public/Asset/webFonts/Gilroy-SemiBold.ttf") format("woff");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../public/Asset/webFonts/Gilroy-Medium.ttf") format("woff2"),
    url("../public/Asset/webFonts/Gilroy-Medium.ttf") format("woff");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../public/Asset/webFonts/Gilroy-Bold.ttf") format("woff2"),
    url("../public/Asset/webFonts/Gilroy-Bold.ttf") format("woff");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../public/Asset/webFonts/Gilroy-ExtraBold.ttf") format("woff2"),
    url("../public/Asset/webFonts/Gilroy-ExtraBold.ttf") format("woff");
  font-weight: 800;
  font-display: swap;
}

.App {
  text-align: center;
}
* {
  font-family: Gilroy, Tahoma;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-bg {
  background-color: #152f4f !important;
  color: #fff !important;
}

.navbar-link {
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin: 0 1rem;
  padding: 0 15px;
}

.navbar-link:hover {
  /* border: 1px solid black; */
  background: rgba(0, 0, 0, 0.1);
  border-radius: 35px;
  /* padding: 0 15px; */
}
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #152f4f;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #fff;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
  display: inline-block;
  margin: 10px; /* Adjust the margin as needed */
  border-radius: 50%;
  overflow: hidden;
  text-decoration: none;
}
.footer-social-icon i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  color: #fff;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
}
.facebook-bg {
  background: #152f4f;
  border: 1px solid #fff;
}
.twitter-bg {
  background: #152f4f;
  border: 1px solid #fff;
}
.google-bg {
  background: #152f4f;
  border: 1px solid #fff;
}
.footer-links {
  display: flex;
  text-decoration: none !important;
  padding: 0 6rem;
}
.footer-links li a {
  font-size: 16px;
  text-decoration: none;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #878787;
}
.footer-widget ul li a {
  color: #fff;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #152f4f;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}
.copyright-text p a {
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.footer-terms {
  font-size: 13px;
  cursor: pointer;
}

#pillars_section .grid {
  padding: 0 !important;
}
#pillars_section li {
  margin: 0 !important;
}
.footer-icons {
  text-align: end;
}
.privacy-term-btn {
  margin: 64px 20px;
}

.header-logo {
  height: 50px;
  padding: 10px 64px;
  margin: 10px 0;
}
#about_section {
  padding-top: 7rem;
}
.footer-terms {
  margin: 0 1rem;
}
@media (max-width: 991px) {
  #about_section {
    padding-top: 0;
  }
  .footer-terms {
    margin: 0 0.5rem;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer-icons {
    text-align: center;
    margin-bottom: 2rem;
  }
  .copyright-area {
    padding: 0 0 15px 0;
  }
  .privacy-terms-heading {
    font-size: 22px !important;
  }
  .privacy-term-btn {
    margin: 15px 5px;
  }
  .header-logo {
    height: 50px;
    padding: 10px 25px;
    margin: 10px 0;
  }
  .footer-social-icon i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    color: #fff;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  .navbar-link:hover {
    /* border: 1px solid black; */
    background: rgba(0, 0, 0, 0);
    /* border-radius: 35px; */
    /* padding: 0 15px; */
  }
}
.navbar-toggler-icon {
  color: #fff !important;
}
.tile-img-container {
  display: flex;
  justify-content: center;
}
.wornder-tile-img {
  height: 384px;
  /* transform: rotate(-90deg); */
}

.custom-toggler {
  border-color: #fff; /* Set border color to white */
  color: #fff; /* Set text color to white */
}
.custom-toggler:focus {
  border-color: #fff; /* Set border color to white */
  color: #fff; /* Set text color to white */
  box-shadow: none !important;
}

.about-desc {
  font-family: Gilroy, Tahoma;
  font-weight: 600;
  text-transform: none;
}

.about-title {
  font-weight: 900;
}

.tile-card {
  padding: 50px 0;
  background-color: #f5f5f5 !important;
}

.about-bg {
  background-color: #152f4f !important;
}

.navbar-links {
  display: inline-block;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  background-color: white;
  color: green;
  border-radius: 5px; /* Add rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition on background color change */
}

.navbar-links:hover {
  background-color: green;
  color: white;
  border-radius: 0; /* Set to 0 to make it rectangular on hover */
}
.nav-link {
  border-radius: 35px;
  border: none;
}

.custom-card-bg {
  background-color: #f5f5f5 !important;
}
