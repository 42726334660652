body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy", Tahoma;
}
.bg-image {
  background-image: url("../public/Asset/web9.png");
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  margin-top: 0rem;
  position: relative;
  z-index: 100;
}

.move-down {
  position: absolute;
  bottom: 0%;
  background-color: azure;
  border-radius: 50%;
  padding: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrow-icon {
  animation: bounceUpDown 1.5s infinite alternate; /* Adjust the duration as needed */
}

@keyframes bounceUpDown {
  0% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(
      -6px
    ); /* Adjust the distance you want the arrow to move */
  }
}

@media screen and (min-width: 600px) and (max-width: 860px) {
  /* Your styles for screens with a width between 600px and 1200px go here */
  .bg-image {
    background-image: url("../public/Asset/web9.png");
    background-origin: border-box;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    height: 31vh;
    margin-top: 0rem;
    position: relative;
    z-index: 100;
    width: 100vw;
  }

  .move-down {
    position: absolute;
    bottom: 0%;
    background-color: azure;
    border-radius: 50%;
    padding: 1rem;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 450px) {
  .bg-image {
    background-image: url("../public/Asset/web_mobile_final.png");
    background-origin: border-box;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60vh;
    margin-top: 0rem;
    position: relative;
    z-index: 100;
    width: 100vw;
  }
  .move-down {
    display: none;
  }
}
